import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Import axios for making API requests
import '../components/profilePage.css';

const ProfilePage = () => {
  const { username } = useParams(); // Get the username from the URL
  const [profileData, setProfileData] = useState(null);
  const [discordUserData, setDiscordUserData] = useState(null); // For Discord user data
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling

  useEffect(() => {
    // Check if username is available in the URL
    if (!username) {
      setError('Username is required');
      setLoading(false);
      return;
    }

    // Make an API request to get user data
const fetchUserData = async () => {
  try {
    const response = await fetch('https://nodejs-serverless-function-express-lime-pi.vercel.app/api/user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: username }),
    });

    const data = await response.json(); // Parse the response as JSON

    if (response.status === 200) { // Check for HTTP status 200
      setProfileData(data.userInfo); // Set the user data
      // Fetch Discord user data after getting the profile data
      fetchDiscordUser(data.userInfo.discordUserId);
    } else {
      setError(data.status.message); // Handle error if user is not found
    }
  } catch (err) {
    console.log(err);
    setError('An error occurred while fetching user data.');
  } finally {
    setLoading(false); // Set loading to false once data is fetched
  }
};


    const fetchDiscordUser = async (discordUserId) => {
      try {
        const response = await fetch(`https://nodejs-serverless-function-express-lime-pi.vercel.app/api/discord/${discordUserId}`);
        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error fetching Discord user data:', errorData);
          return;
        }

        const userData = await response.json();
        console.log(userData);  // Check the returned Discord user data
        setDiscordUserData(userData); // Set Discord user data
      } catch (error) {
        console.error('Network error or invalid JSON:', error);
      }
    };

    fetchUserData();
  }, [username]); // Dependency on `username` so the effect re-runs when it changes

  <meta name='viewport' content='width=device-width, initial-scale=1.0'></meta>
  if (loading) {
    return <div className="profile-box"><h1>Loading...</h1></div>;
  }

  if (error) {
    return (
      <div className="profile-box">
        <h1>{error}</h1>
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className="profile-box">
        <h1>User not found</h1>
      </div>
    );
  }

  return (
    <div>
      {/* Background video */}
      <video autoPlay muted loop id="background-video">
        <source src={profileData.backgroundPicture} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Profile Box */}
      <div className="profile-box">
        {/* Banner */}
        <div className="banner-box">
          <img
            src={profileData.profileBanner}
            alt="Banner"
          />
        </div>

        {/* Profile Picture */}
        <div className="profile-picture">
          <img
            src={profileData.profilePicture}
            alt="Profile"
          />
        </div>

        {/* Profile Name and Description */}
        <div className="profile-name" data-name={profileData.UID}>{profileData.username}</div>
        <div className="profile-description">{profileData.description}</div>

        {/* Social Icons */}
        <div className="social-icons">
          {profileData.github && (
            <div className="icon-circle" data-name="GitHub">
              <a href={profileData.github} target="_blank" rel="noopener noreferrer">
                <img src="assets/icons/github.svg" alt="GitHub Icon" />
              </a>
            </div>
          )}
          {profileData.tiktok && (
            <div className="icon-circle" data-name="TikTok">
              <a href={profileData.tiktok} target="_blank" rel="noopener noreferrer">
                <img src="assets/icons/tiktok.svg" alt="TikTok Icon" />
              </a>
            </div>
          )}
          {profileData.discord && (
            <div className="icon-circle" data-name="Discord">
              <a href={profileData.discord} target="_blank" rel="noopener noreferrer">
                <img src="assets/icons/discord.svg" alt="Discord Icon" />
              </a>
            </div>
          )}
          {profileData.telegram && (
            <div className="icon-circle" data-name="Telegram">
              <a href={profileData.telegram} target="_blank" rel="noopener noreferrer">
                <img src="assets/icons/telegram.svg" alt="Telegram Icon" />
              </a>
            </div>
          )}
        </div>

        {/* Footer Box with Discord link and user info */}
        <div className="footer-box">
          <div className="discord-user">
            {discordUserData && (
              <>
                {/* Discord Avatar */}
                <div className="avatar">
                  <img src={discordUserData.user?.avatar} alt="Avatar" />
                </div>
                {/* Discord Username */}
                <div className="username">
                  @{discordUserData.user?.username}
                </div>
                {/* Badges */}
                <div className="badges">
                  {discordUserData.user?.badges?.map((badge, index) => (
                    <div className="badge-icon" key={index} data-tooltip={badge}>
                      <img src={`assets/badges/${badge}.png`} alt={badge} />
                    </div>
                  ))}
                </div>
              </>
            )}

            {/* "Add on Discord" Button */}
            <div className="discord-user-button">
              <a
                href={`https://discord.com/users/${profileData.discordUserId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="add-button"
              >
                Add on Discord
                <img src="assets/icons/arrow-right-short.svg" alt="Arrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
